<template>
  <div>
    <custom-table
      ref="customTable"
      :columns="columns"
      :custom-view="customView"
      :footer-actions="footerActions"
      api-url="/invoice-wait-process"
      api-download-url="/invoice-wait-process-export"
      download-file-name="can-xu-ly.xlsx"
      table-height="calc(100vh - 195px)"
      @showDialogProcess="showDialogProcess"
      @rerunOrder="rerunOrder"
      @downloadGoodsWaitProcess="downloadGoodsWaitProcess"
    ></custom-table>

    <v-dialog v-model="processDialog" persistent max-width="1200px">
      <DialogProcess
        v-if="processDialog"
        :item="updatingItem"
        @cancel="hideDialogProcess"
        @success="refreshCustomTable"
      />
    </v-dialog>
  </div>
</template>

<script>
import {
  CUSTOM_VIEW_BILL_WAIT_PROCESS,
  INVOICE_WAIT_STATUS,
  ORDER_SOURCES,
  YES_NO_OPTIONS,
} from "@/libs/const";
import { httpClient } from "@/libs/http";

export default {
  name: "WaitProcess",
  components: {
    DialogProcess: () => import("@/components/invoice/DialogProcess"),
  },
  data: () => ({
    columns: [],
    customView: CUSTOM_VIEW_BILL_WAIT_PROCESS,
    sourceOptions: [...ORDER_SOURCES],
    yesNoOptions: [...YES_NO_OPTIONS],
    waitStatusOptions: [...INVOICE_WAIT_STATUS],
    footerActions: [],
    processDialog: false,
    isLoading: false,
    updatingItem: {},
  }),
  created() {
    const columns = [
      {
        type: "input-filter",
        label: this.$t("labels.tracking"),
        placeholder: this.$t("labels.tracking"),
        name: "tracking_id",
        hasSort: false,
        sortName: "tracking_id",
        key: "tracking_id",
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.ssc_process"),
        placeholder: this.$t("labels.ssc_process"),
        name: "is_ssc",
        hasSort: false,
        sortName: "is_ssc",
        key: "is_ssc",
        options: this.yesNoOptions,
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.status"),
        placeholder: this.$t("labels.status"),
        name: "status",
        hasSort: false,
        sortName: "status",
        key: "status",
        options: this.waitStatusOptions,
        required: true,
      },
      {
        type: "select-filter",
        label: this.$t("labels.income"),
        placeholder: this.$t("labels.income"),
        name: "is_income",
        hasSort: false,
        sortName: "is_income",
        key: "is_income",
        options: this.yesNoOptions,
        required: true,
      },
      /* {
        type: "select-employee",
        label: this.$t("labels.account"),
        placeholder: this.$t("labels.account"),
        name: "id_identity",
        hasSort: false,
        sortName: "id_identity",
        key: "identity_name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.household_business"),
        placeholder: this.$t("labels.household_business"),
        name: "tax_name",
        hasSort: false,
        sortName: "tax_name",
        key: "tax_name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.tax_code"),
        placeholder: this.$t("labels.tax_code"),
        name: "tax_code",
        hasSort: false,
        sortName: "tax_code",
        key: "tax_code",
        required: true,
      }, */
      {
        type: "select-filter",
        label: this.$t("labels.order_source"),
        placeholder: this.$t("labels.order_source"),
        name: "source",
        hasSort: false,
        sortName: "source",
        key: "source",
        options: this.sourceOptions,
        required: true,
      },
      {
        type: "select-e-market-shop",
        label: this.$t("labels.shop"),
        placeholder: this.$t("labels.shop"),
        class: "c-input-xs input-filter",
        name: "id_customer_e_marketplace_shop",
        hasSort: false,
        sortName: "customer_e_marketplace_shop_name",
        key: "customer_e_marketplace_shop_name",
        required: true,
        params: {
          "is-check-e-market": false,
          "is-show-publish-invoice-only": true,
        },
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.create_time"),
        placeholder: this.$t("labels.create_time"),
        name: "created_at",
        hasSort: false,
        sortName: "created_at",
        key: "created_at",
        required: true,
      },
      {
        type: "date-range-filter",
        label: this.$t("labels.order_created_at"),
        placeholder: this.$t("labels.order_created_at"),
        name: "order_created_at",
        hasSort: false,
        sortName: "order_created_at",
        key: "order_created_at",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.customer_order_id"),
        placeholder: this.$t("labels.customer_order_id"),
        name: "customer_order_id",
        hasSort: false,
        sortName: "customer_order_id",
        key: "customer_order_id",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.adjustment_type"),
        placeholder: this.$t("labels.adjustment_type"),
        name: "type",
        hasSort: false,
        sortName: "type",
        key: "type",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.reason"),
        placeholder: this.$t("labels.reason"),
        name: "reason",
        hasSort: false,
        sortName: "reason",
        key: "reason",
        required: true,
      },
      {
        type: "button",
        label: "",
        headerAction: "",
        headerActionColor: "",
        headerActionLabel: "",
        action: false,
        required: true,
        buttonActions: [
          {
            action: "showDialogProcess",
            text: this.$t("labels.process"),
            color: "warning",
            class: "",
            permissions: [],
            allowKey: "can_process",
          },
        ],
      },
    ];
    this.columns = [...columns];

    const footerActions = [
      {
        text: this.$t("labels.goods_wait_process"),
        action: "downloadGoodsWaitProcess",
        link: "",
        permissions: [],
        color: "warning",
        class: "mr-2",
      },
      {
        text: this.$t("labels.rerun"),
        action: "rerunOrder",
        link: "",
        permissions: [],
        color: "success",
        class: "mr-2",
      },
    ];
    this.footerActions = [...footerActions];
  },
  methods: {
    showDialogProcess(item) {
      this.updatingItem = { ...item };
      this.processDialog = true;
    },
    hideDialogProcess() {
      this.updatingItem = {};
      this.processDialog = false;
    },
    refreshCustomTable() {
      this.$emit("getCount", true);
      this.$refs.customTable.getList();
    },
    async rerunOrder() {
      if (!confirm("Bạn đã kiểm tra lại dữ liệu chưa?")) {
        return false;
      }

      try {
        const { data } = await httpClient.post("/invoice-process-rerun");
        console.log(data);
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    async downloadGoodsWaitProcess() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      const filename = "hang-hoa-can-xu-ly.xlsx";

      try {
        await this.downloadExcelFile(
          "/invoice-download-goods-wait-process",
          {},
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>
